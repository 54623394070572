<template>
  <div class='category-result'>
    <div class="inner">
      <div class="wrapper">
        <div class='category-name'>
          <div class="row">
            <span class="name">{{category.name}}</span>
            <div class="score">
              <span class='average'>{{$formatValue(category.average)}}</span>
              <span class="maximum">/5</span>
            </div>
          </div>
        </div>
        <trial-question-summary-table :questions="category.questionResults"/>
        <trial-question-result v-for='(question, index) in category.questionResults' :key='index'
                         :question='question' />
      </div>
    </div>
  </div>
</template>

<script>
import TrialQuestionResult from '@/components/Result/TrialQuestionResult.vue';
import TrialQuestionSummaryTable from '@/components/Result/TrialQuestionSummaryTable.vue';

export default {
  name: 'TrialCategoryResult',
  components: {
    TrialQuestionResult,
    TrialQuestionSummaryTable,
  },
  props: {
    category: Object,
  },
};
</script>

<style lang="scss" scoped>
  @import '~@/assets/style/variable.scss';

  .category-result {
    margin-bottom: 48px;
    border-radius: 8px;
    box-shadow: 0 0 5px 0 rgba(42, 50, 75, 0.08);
    padding: 32px 36px;
    border: solid 1px #dadce0;
    background-color: #ffffff;
    &:last-child {
      margin-bottom: 0;
    }
    .inner {
      .category-name {
        font-size: $font_size_20;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 24px;
        color: #2A324B;
        .row {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 8px;
          .name {
            font-weight: 500;
            font-size: $font_size_24;
            color: #2A324B;
            line-height: 1.38;
          }
          .score {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            margin-left: 20px;
            font-weight: bold;
            font-size: $font_size_21;
            letter-spacing: normal;
            .average {
              color: #00CA94;
              margin-right: 7px;
            }
            .maximum {
              color: #dadce0;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: $max_width_size_for_mobile) {
    .category-result {
      .wrapper {
        padding: 24px !important;
      }
      .inner {
        .category-name {
          margin-bottom: 1em;
        }
      }
    }
  }
</style>
