<template>
  <div class='trial-labs-bar-chart'>
    <canvas id="myChart"></canvas>
    <div class="labels">
      <div class="label" v-for="(label, index) in this.data.uniqueLabel" :key="index">
        <div class="box" :style="{backgroundColor: uniqueBackgroundColor[index]}"></div>
        <div class="text">{{label}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.plugins.register({
  categoryAverageLine(chartInstance, line) {
    const context = chartInstance.chart.ctx;

    const xStart = chartInstance.scales['x-axis-0'].getPixelForValue(line.xStart - 0.5);
    const xEnd = chartInstance.scales['x-axis-0'].getPixelForValue(line.xEnd - 0.5);
    const yValue = chartInstance.scales['y-axis-0'].getPixelForValue(line.yValue);
    const xLabel = chartInstance.scales['x-axis-0'].getPixelForValue(line.xEnd - 1);
    const yLabel = (line.yValue > 4.7) ? chartInstance.scales['y-axis-0'].getPixelForValue(line.yValue - 0.2) : chartInstance.scales['y-axis-0'].getPixelForValue(line.yValue + 0.1);

    context.beginPath();
    context.strokeStyle = 'rgba(42, 50, 75, 0.5)';
    context.lineWidth = 1;
    context.moveTo(xStart, yValue);
    context.lineTo(xEnd, yValue);
    context.stroke();

    context.fillStyle = '#2a324b';
    context.font = '18px "Helvetica Neue"';
    context.textAlign = 'center';
    context.fillText(line.label, xLabel, yLabel);
  },
  afterDatasetsDraw(chart) {
    if (chart.data.datasets[0].data[0] === '-') {
      return;
    }
    if (chart.config.lines) {
      chart.config.lines.map(line => this.categoryAverageLine(chart, line));
    }
  },
});
Chart.plugins.unregister(ChartDataLabels);

export default {
  name: 'TrialLabsBarChart',
  props: {
    data: Object,
  },
  mounted() {
    this.getChart();
  },
  computed: {
    uniqueBackgroundColor() {
      return Array.from(new Set(this.data.backgroundColor));
    },
  },
  methods: {
    getChart() {
      const chart = document.getElementById('myChart');
      return new Chart(chart, {
        type: 'bar',
        data: {
          labels: this.data.label,
          datasets: [
            {
              label: 'bar chart',
              backgroundColor: this.data.backgroundColor,
              data: this.data.average,
              questionText: this.data.questionText,
            },
          ],
        },
        lines: this.data.categoryAverage,
        options: {
          legend: {
            display: false,
          },
          scales: {
            xAxes: [{
              ticks: {
                minRotation: 45,
                maxRotation: 45,
              },
            }],
            yAxes: [{
              ticks: {
                beginAtZero: true,
                max: 5,
                stepSize: 1,
              },
            }],
          },
          tooltips: {
            callbacks: {
              /* eslint dot-notation: "off" */
              title: (tooltipItem, data) => data['labels'][tooltipItem[0]['index']],
              label: (tooltipItem, data) => data['datasets'][0]['questionText'][tooltipItem['index']],
            },
          },
        },
      });
    },
  },
  watch: {
    data() {
      this.getChart();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/variable.scss";

.trial-labs-bar-chart {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  canvas {
    width: 1000px;
  }
  .labels {
    display: flex;
    flex-direction: row;
    margin-top: 18px;
    .label {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 24px;
      .box {
        width: 20px;
        height: 7px;
        margin-right: 5px;
      }
      .text {
        font-size: $font_size_12;
      }
    }
  }
}
@media only screen and (max-width: $max_width_size_for_mobile) {
  .trial-labs-radar-chart {
    width: 720px !important;
  }
}
</style>
