<template>
  <div class="trial-question-summary" id="trial-question-summary">
    <div class="wrapper">
      <div class='title'>{{ $t('result_info.submission_question') }}</div>
      <div v-if="hasSubmission">
        <trial-category-result v-for="(category, index) in trialFeedback.categoryResults"
          :key="index" :category="category"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TrialCategoryResult from '@/components/Result/TrialCategoryResult.vue';

export default {
  name: 'TrialQuestionSummary',
  components: {
    TrialCategoryResult,
  },
  created() {
    this.fetchTrialFeedback(this.$route.params.id);
  },
  computed: {
    ...mapGetters(['trialFeedback']),
    hasSubmission() {
      return this.trialFeedback.totalCount > 0;
    },
  },
  methods: {
    ...mapActions(['fetchTrialFeedback']),
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/style/variable.scss';

.trial-question-summary {
  width: 100vw;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.16);
  margin-top: 58px;
  .wrapper {
    max-width: $max_width;
    padding-top: $standard_padding_size;
    padding-bottom: $standard_padding_size;
    position: relative;
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 100%;
  }
  .title {
    margin-bottom: 25px;
    padding-bottom: 10px;
    font-size: $font_size_22;
    line-height: 1.36;
    letter-spacing: normal;
    font-weight: bold;
  }
}
</style>
