<template>
  <div class="trial-rating-label" :class="{vertical: isVerticalLabel, [labelStyle]: true}">
    <div v-for="(choice, index) in choices" :key="`label-${index}`" class="label">
      <div class="color"/>
      <span>{{choice.id}} : {{choice.text}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TrialRatingLabel',
  props: {
    choices: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    labelStyle() {
      return `label-${this.choices.length}`;
    },
    isVerticalLabel() {
      return this.choices.some(({ text }) => text.length > 10);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/variable.scss";

.trial-rating-label {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 12px;
  &.vertical {
    flex-direction: column;
  }
  .label {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 24px;
    margin-bottom: 5px;
    font-size: $font_size_12;
    letter-spacing: normal;
    color: #707070;
    &:last-child {
      margin-right: 0;
    }
    .color {
      min-width: 40px;
      height: 14px;
      margin-right: 5px;
      border-radius: 1px;
    }
  }
  &.label-2 .label:nth-child(1) .color { background-color: #e5faf4; }
  &.label-2 .label:nth-child(2) .color { background-color: #19cf9e; }

  &.label-3 .label:nth-child(1) .color { background-color: #e5faf4; }
  &.label-3 .label:nth-child(2) .color { background-color: #7fe4c9; }
  &.label-3 .label:nth-child(3) .color { background-color: #19cf9e; }

  &.label-4 .label:nth-child(1) .color { background-color: #e5faf4; }
  &.label-4 .label:nth-child(2) .color { background-color: #a6edda; }
  &.label-4 .label:nth-child(3) .color { background-color: #66dfbf; }
  &.label-4 .label:nth-child(4) .color { background-color: #19cf9e; }

  &.label-5 .label:nth-child(1) .color { background-color: #e5faf4; }
  &.label-5 .label:nth-child(2) .color { background-color: #b2efdf; }
  &.label-5 .label:nth-child(3) .color { background-color: #7fe4c9; }
  &.label-5 .label:nth-child(4) .color { background-color: #4cdab4; }
  &.label-5 .label:nth-child(5) .color { background-color: #19cf9e; }

  &.label-6 .label:nth-child(1) .color { background-color: #e5faf4; }
  &.label-6 .label:nth-child(2) .color { background-color: #bff2e4; }
  &.label-6 .label:nth-child(3) .color { background-color: #99ead4; }
  &.label-6 .label:nth-child(4) .color { background-color: #73e2c4; }
  &.label-6 .label:nth-child(5) .color { background-color: #40d7af; }
  &.label-6 .label:nth-child(6) .color { background-color: #19cf9e; }

  &.label-7 .label:nth-child(1) .color { background-color: #e5faf4; }
  &.label-7 .label:nth-child(2) .color { background-color: #bff2e4; }
  &.label-7 .label:nth-child(3) .color { background-color: #a6edda; }
  &.label-7 .label:nth-child(4) .color { background-color: #8ce7cf; }
  &.label-7 .label:nth-child(5) .color { background-color: #66dfbf; }
  &.label-7 .label:nth-child(6) .color { background-color: #40d7af; }
  &.label-7 .label:nth-child(7) .color { background-color: #19cf9e; }

}
@media only screen and (max-width: $max_width_size_for_mobile) {
  .rating-label {
    .label .color {
      width: 14px;
      height: 14px;
    }
  }
}
</style>
