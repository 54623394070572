<template>
  <div class="trial-result-basic-info">
    <div class="wrapper">
      <div class="left">
        <div class="title-team-name">
          <div class="title">{{trialSurvey.name}}</div>
          <span class="period">
              {{ $t('result_info.last_date') }} {{createdDate}} | {{ $t('result_info.first_date') }} {{updatedDate}}
          </span>
        </div>
      </div>
      <div class="right">
        <div class="detail">
          <div class="fake-image" />
          <span class="caption">{{ $t('result_info.number_respondents_today') }}</span>
          <span class="day">{{ responseQuantity(trialFeedback.todayCount) }}</span>
        </div>
        <div class="detail">
          <v-popover offset="3" trigger="hover">
            <div class="info-image"/>
            <template slot="popover">
              <div class="popover-result" >
                <div class="response-text">{{ $t('result_info.response') }}</div>
                <div class="response-container">
                  <div class="item-container">
                    <span class="header-text">{{ $t('result_info.today') }}</span>
                    <span class="number">{{trialFeedback.todayCount}}</span>
                  </div>
                  <div class="item-container">
                    <span class="header-text">{{ $t('result_info.last_week') }}</span>
                    <span class="number">{{trialFeedback.weekCount}}</span>
                  </div>
                  <div class="item-container">
                    <span class="header-text">{{ $t('result_info.last_month') }}</span>
                    <span class="number">{{trialFeedback.monthCount}}</span>
                  </div>
                </div>
                <div class="total-container">
                  <span class="total">{{ $t('result_info.total') }}</span>
                  <span class="total">{{trialFeedback.totalCount}}</span>
                </div>
              </div>
            </template>
          </v-popover>
          <span class="caption">{{ $t('result_info.number_respondents_total') }}</span>
          <span class="day">{{ responseQuantity(trialFeedback.totalCount) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { progressType } from '@/assets/js/code';
import { format } from '@/common/date.util';

export default {
  name: 'TrialResultBasicInfo',
  computed: {
    ...mapGetters(['trialSurvey', 'trialFeedback']),
    createdDate() {
      return format(this.trialSurvey.createdDate, 'YYYY.MM.DD');
    },
    updatedDate() {
      if (this.trialSurvey.updatedDate === null) {
        return this.createdDate;
      }
      return format(this.trialSurvey.updatedDate, 'YYYY.MM.DD');
    },
  },
  data() {
    return {
      progressType,
    };
  },
  async created() {
    await this.fetchTrialSurvey();
    await this.fetchTrialFeedback();
  },
  methods: {
    ...mapActions(['fetchTrialSurvey', 'fetchTrialFeedback']),
    responseQuantity(count) {
      return count <= 1 ? this.$t('result_info.person_count', { count }) : this.$t('result_info.people_count', { count });
    }
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/style/variable.scss';

.trial-result-basic-info {
  width: 100%;
  height: 123px;
  background-color: rgba(105, 125, 190, 0.03);
  .wrapper {
    max-width: $max_width;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: auto;
    flex-wrap: wrap;
    .left {
      display: flex;
      flex-direction: row;
      margin-top: 45px;
      .title-team-name {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        .title {
          width: 100%;
          font-size: $font_size_24;
          font-weight: 600;
          letter-spacing: normal;
          color: #2a324b;
          display: flex;
          align-items: center;
          line-height: 1.38;
          word-break: break-word;
          word-wrap: break-word;
          white-space: normal;
        }
        .period {
          height: 17px;
          font-size: $font_size_12;
          line-height: 1.42;
          letter-spacing: normal;
          text-align: right;
          color: #acacac;
        }
      }
    }
    .right {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      align-self: center;
      .detail {
        display: flex;
        flex-direction: column;
        border-right: 1px solid #f2f2f2;
        padding: 0 24px;
        &:last-child {
          border-right: none;
          padding-right: 0;
        }
        .fake-image {
          width: 16px;
          height: 16px;
        }
        .v-popover {
          display: flex;
          align-self: flex-end;
          .info-image {
            width: 16px;
            height: 16px;
            background-size: 16px 16px;
            content: url("~@/assets/images/info_grey.svg");
            background-color: transparent;
            background-repeat: no-repeat;
            &:hover {
              content: url("~@/assets/images/info_green.svg");
            }
          }
        }
        .caption {
          font-size: $font_size_14;
          line-height: 1.36;
          letter-spacing: normal;
          text-align: right;
          color: #9b9b9b;
        }
        .day {
          font-size: $font_size_16;
          font-weight: 600;
          line-height: 1.38;
          letter-spacing: normal;
          text-align: right;
          color: #2a324b;
        }
      }
    }
  }
  @media only screen and (max-width: $max_width_size_for_mobile) {
    height: auto;
    padding: 48px 24px;
    .wrapper {
      .left {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 0;
      }
      .right {
        width: 100%;
        margin-top: 12px;
        margin-right: 12px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    }
  }
}
.popover-result {
  width: 194px;
  padding: 12px;
  .response-text {
    font-size: $font_size_12;
    font-weight: bold;
    line-height: 1.42;
    letter-spacing: normal;
    color: #2a324b;
  }
  .response-container {
    margin-top: 14px;
    padding-bottom: 2px;
    border-bottom: solid 0.5px #dadce0;
    .item-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .header-text {
        font-size: $font_size_14;
        font-weight: 500;
        line-height: 1.29;
        letter-spacing: -0.35px;
        color: #a9adb7;
      }
      .number {
        font-size: $font_size_14;
        line-height: 1.29;
        letter-spacing: normal;
        color: #4a4a4a;
      }
    }
  }
  .total-container {
    margin-top: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .total {
      font-size: $font_size_14;
      font-weight: bold;
      line-height: 1.29;
      letter-spacing: -0.35px;
      color: #4a4a4a;
    }
  }
}
</style>
