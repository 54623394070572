<template>
  <div class="trial-visualization">
    <trial-labs-bar-chart v-if="currentChart==='LABS_BAR_CHART'"
      :data="chartBarData"/>
    <trial-labs-radar-chart v-if="currentChart==='LABS_RADAR_CHART'"
      :data="chartRadarData"/>
    <div class="graph-button-bar">
      <input type="button" class="bar" :class="{active: currentChart==='LABS_BAR_CHART'}"
        @click="showLabsBarChart"/>
      <input type="button" class="radar" :class="{active: currentChart==='LABS_RADAR_CHART'}"
        @click="showLabsRadarChart"/>
    </div>
</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TrialLabsBarChart from '@/components/Result/TrialLabsBarChart.vue';
import TrialLabsRadarChart from '@/components/Result/TrialLabsRadarChart.vue';

export default {
  name: 'TrialVisualization',
  components: {
    TrialLabsBarChart,
    TrialLabsRadarChart,
  },
  data() {
    return {
      currentChart: 'LABS_BAR_CHART', // LABS_BAR_CHART, LABS_RADAR_CHART
      backgroundColor: [
        'rgb(124, 181, 216, 0.65)',
        'rgb(114, 149, 215, 0.65)',
        'rgb(105, 114, 213, 0.65)',
        'rgb(124, 106, 213, 0.65)',
        'rgb(153, 108, 214, 0.65)',
        'rgb(187, 110, 214, 0.65)',
        'rgb(206, 111, 201, 0.65)',
        'rgb(206, 111, 168, 0.65)',
        'rgb(206, 110, 136, 0.65)',
        'rgb(206, 112, 107, 0.65)'],
      fontColor: [
        'rgb(124, 181, 216)',
        'rgb(114, 149, 215)',
        'rgb(105, 114, 213)',
        'rgb(124, 106, 213)',
        'rgb(153, 108, 214)',
        'rgb(187, 110, 214)',
        'rgb(206, 111, 201)',
        'rgb(206, 111, 168)',
        'rgb(206, 110, 136)',
        'rgb(206, 112, 107)'],
    };
  },
  computed: {
    ...mapGetters(['trialFeedback']),
    chartBarData() {
      const questionResults = this._.flatten(this.trialFeedback.categoryResults
          .map(category => category.questionResults.map(q => ({
                questionText: q.text,
                questionAverage: this.$formatValue(q.average),
                categoryId: category.id,
                categoryName: category.name,
                categoryAverage: category.average === -1 ? 0 : this.$formatValue(category.average),
              }))));
      const labelIds = this.trialFeedback.categoryResults
          .map(category => category.id);
      const uniqueLabels = this.trialFeedback.categoryResults
          .map(category => category.name);
      const labels = questionResults.map((q, index) => {
        const label = [(index + 1), q.categoryName];
        return label.join('. ');
      });
      const averages = questionResults.map(q => q.questionAverage);
      const questionTexts = questionResults.map(q => q.questionText);
      const backgroundColors = questionResults.map(q => this.backgroundColor[
        labelIds.indexOf(q.categoryId)]);
      const categoryAverages = this.trialFeedback.categoryResults.map((category) => {
        const chartInfo = {
          xStart: category.questionResults[0].no - 1,
          xEnd: category.questionResults[category.questionResults.length - 1].no,
          yValue: category.average,
          color: this.fontColor[this.trialFeedback.categoryResults.indexOf(category)],
          label: category.average === -1 ? 0 : this.$formatValue(category.average),
        };
        return chartInfo;
      });

      const barData = {
        uniqueLabel: uniqueLabels,
        label: labels,
        average: averages,
        categoryAverage: categoryAverages,
        questionText: questionTexts,
        backgroundColor: backgroundColors,
      };

      return barData;
    },
    chartRadarData() {
      return this.trialFeedback.categoryResults
              .map(category => ({
                id: category.id,
                category: category.name,
                average: category.average === -1 ? 0 : this.$formatValue(category.average),
              }));
    },
  },
  methods: {
    ...mapActions(['fetchTrialFeedback']),
    showLabsBarChart() {
      this.currentChart = 'LABS_BAR_CHART';
    },
    showLabsRadarChart() {
      this.currentChart = 'LABS_RADAR_CHART';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/style/variable.scss';
.trial-visualization {
  flex-direction: column;
  width: 100%;
  min-height: 718px;
  margin-bottom: 48px;
  &.active {
    display: flex;
  }
  .graph-button-bar {
    display: flex;
    justify-content: center;
    .bar, .radar {
      background-size: 16px 16px;
      width: 28px;
      height: 28px;
      border: none;
      border-radius: 50%;
      background-position:center center;
      background-repeat: no-repeat;
      background-color: $white;
      cursor: pointer;
      transition: all .15s ease-in-out;
      &:hover {
        background-color: rgba(42,50,75, .1);
      }
      &:focus {
        outline: none;
      }
      &.active {
        background-color: $bunting_blue;
      }
    }
    .bar {
      margin-right: 6px;
      background-image: url("~@/assets/images/bar-chart-icon.svg");
      &.active {
        background-image: url("~@/assets/images/bar-chart-icon-active.svg");
      }
    }
    .radar {
      background-image: url("~@/assets/images/radar-chart-icon.svg");
      &.active {
        background-image: url("~@/assets/images/radar-chart-icon-active.svg");
      }
    }
  }
}
@media only screen and (max-width: $max_width_size_for_mobile) {
  .trial-visualization {
    display: flex;
    flex-direction: column;
    .graph-button-bar {
      left: 0;
      position: absolute;
      right: 0;
    }
  }
}
</style>
