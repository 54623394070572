<template>
  <div class="total-average">
    <div class="average" v-if="hasSubmission">
      <span class="label">{{ $t('score') }}</span>
      <span class="score">{{$formatValue(trialFeedback.average)}}</span>
      <span class="total">/5</span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TrialTotalAverage',
  async created() {
    await this.fetchTrialFeedback();
  },
  computed: {
    ...mapGetters(['trialFeedback']),
    hasSubmission() {
      return this.trialFeedback.totalCount > 0;
    },
  },
  methods: {
    ...mapActions(['fetchTrialFeedback']),
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/assets/style/variable.scss";

  .total-average {
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    align-items: center;
    .average {
      display: flex;
      margin-right: 11px;
      .label {
        font-size: $font_size_16;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: right;
        color: #2a324b;
        margin-right: 8px;
        margin-top: 1px;
      }
      .score {
        display: flex;
        align-items: center;
        font-size: $font_size_28;
        font-weight: bold;
        letter-spacing: normal;
        text-align: right;
        color: #00ca94;
        margin-right: 10px;
        height: 30px;
        margin-bottom: 1px;
      }
      .total {
        display: flex;
        align-items: center;
        font-size: $font_size_28;
        font-weight: bold;
        letter-spacing: normal;
        text-align: right;
        color: #dadce0;
        height: 30px;
        margin-bottom: 1px;
      }
    }
  }
</style>
