<template>
  <div class='trial-question-result' :id="`trial-question-result-${question.no}`">
    <div class='question-text'>
      <div class='number'>Q{{question.no}}</div>
      <div class="text">
        <span class="keyword">{{question.keyword}}</span>
        <span class="q-text">{{question.text}}</span>
      </div>
    </div>
    <div class='answer-list'>
      <trial-rating-label :choices="question.choiceResults" />
      <trial-percent-stacked-bar :question="question" />
    </div>
  </div>
</template>

<script>
import TrialRatingLabel from '@/components/Result/TrialRatingLabel.vue';
import TrialPercentStackedBar from '@/components/Result/TrialPercentStackedBar.vue';

export default {
  name: 'TrialQuestionResult',
  props: {
    question: Object,
  },
  components: {
    TrialRatingLabel,
    TrialPercentStackedBar,
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/style/variable.scss';
.trial-question-result {
  position: relative;
  margin-bottom: 48px;
  display: flex;
  flex-direction: column;
  width: 100%;
  &:last-child {
    margin-bottom: 0;
  }
  .question-text {
    display: flex;
    flex-direction: row;
    margin-bottom: 13px;
    font-size: $font_size_16;
    width: 100%;
    .number {
      display: block;
      margin-right: 8px;
      font-weight: bold;
      min-width: 30px;
      text-align: right;
    }
    .text {
      display: flex;
      flex-direction: row;
      align-items: center;
      .keyword {
        align-items: center;
        padding: 0 5px;
        border-radius: 3px;
        background-color: #f2f2f2;
        font-size: $font_size_11;
        letter-spacing: -0.25px;
        font-weight: $font_weight_400;
        color: #2a324b;
        vertical-align: text-bottom;
        margin-right: 8px;
        display: inline;
        white-space: nowrap;
      }
      .q-text {
        display: inline;
        white-space: pre-wrap;
        line-height: inherit;
        word-break: break-word;
        word-wrap: break-word;
      }
    }
  }
  .answer-list {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-left: 40px;
    width: 100%;
  }
}
@media only screen and (max-width: $max_width_size_for_mobile) {
  .question-result {
    .question-text {
      display: flex;
      flex-direction: row;
      .number {
        text-align: left;
      }
      .question-text {
        display: flex;
        font-size: $font_size_14;
        .keyword {
          display: flex;
          flex-direction: column;
          max-height: 18px;
          padding: 0 6px;
          width: fit-content;
        }
        .q-text {
          margin-left: -38px;
          width: calc(100% + 38px);
        }
        > span {
          display: block;
          word-break: break-all;
          white-space: pre-wrap;
          &:last-child {
            padding-top: 5px;
            position: relative;
            left: -40px;
            width: calc(100vw - 97px);
          }
        }
      }
    }
  }
}
</style>
