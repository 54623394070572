<template>
  <div class="trial-percent-stacked-bar">
    <div class="graph" :class="graphColorStyle">
      <div v-for="(choice, index) in question.choiceResults" :key="index"
        class="choice" :class="`choice-${index+1}`" :style="getStyle(choice)">
        <v-popover offset="3" placement="top" popoverClass="tooltip" trigger="hover">
          <p class="target"
            :class="{bold: _.maxBy(question.choiceResults, 'count').count === choice.count}">
            {{choice.count > 0 ? getLabel(choice) : ''}}
          </p>
          <template slot="popover">
            <div class="target-tooltip">
              <span>{{getLabel(choice)}}</span>
              <span class="light">{{choice.text}}</span>
            </div>
          </template>
        </v-popover>
      </div>
    </div>
    <div class="count">
      {{ $t('total_count', { count: question.count }) }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'TrialPercentStackedBar',
  props: {
    question: Object,
  },
  computed: {
    graphColorStyle() {
      return `color-group-${this.question.choiceResults.length}`;
    },
  },
  methods: {
    getStyle(choice) {
      const totalCount = this.question.count + this.question.naCount;
      return totalCount === 0 || choice.percent > 0 ? { width: `${choice.percent}%` }
        : { width: '6px' };
    },
    getLabel(choice) {
      return `${this.$formatValue(choice.percent, '%')} (${choice.count <= 1 ? this.$t('result_info.people_count', { count: choice.count }) : this.$t('result_info.person_count', { count: choice.count })})`;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/style/variable.scss";
.trial-percent-stacked-bar {
  width: 100%;
  height: 32px;
  min-height: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .graph {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    background-color: #f7f8fa;
    .choice {
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: $font_size_12;
      letter-spacing: normal;
      color: #707070;
      background-color: #f7f8fa;
    }
  }

  .count {
    width: 60px;
    font-size: $font_size_12;
    letter-spacing: normal;
    color: #707070;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .graph.color-group-2 .choice.choice-1 { background-color: #e5faf4; }
  .graph.color-group-2 .choice.choice-2 { background-color: #19cf9e; }

  .graph.color-group-3 .choice.choice-1 { background-color: #e5faf4; }
  .graph.color-group-3 .choice.choice-2 { background-color: #7fe4c9; }
  .graph.color-group-3 .choice.choice-3 { background-color: #19cf9e; }

  .graph.color-group-4 .choice.choice-1 { background-color: #e5faf4; }
  .graph.color-group-4 .choice.choice-2 { background-color: #a6edda; }
  .graph.color-group-4 .choice.choice-3 { background-color: #66dfbf; }
  .graph.color-group-4 .choice.choice-4 { background-color: #19cf9e; }

  .graph.color-group-5 .choice.choice-1 { background-color: #e5faf4; }
  .graph.color-group-5 .choice.choice-2 { background-color: #b2efdf; }
  .graph.color-group-5 .choice.choice-3 { background-color: #7fe4c9; }
  .graph.color-group-5 .choice.choice-4 { background-color: #4cdab4; }
  .graph.color-group-5 .choice.choice-5 { background-color: #19cf9e; }

  .graph.color-group-6 .choice.choice-1 { background-color: #e5faf4; }
  .graph.color-group-6 .choice.choice-2 { background-color: #bff2e4; }
  .graph.color-group-6 .choice.choice-3 { background-color: #99ead4; }
  .graph.color-group-6 .choice.choice-4 { background-color: #73e2c4; }
  .graph.color-group-6 .choice.choice-5 { background-color: #40d7af; }
  .graph.color-group-6 .choice.choice-6 { background-color: #19cf9e; }

  .graph.color-group-7 .choice.choice-1 { background-color: #e5faf4; }
  .graph.color-group-7 .choice.choice-2 { background-color: #bff2e4; }
  .graph.color-group-7 .choice.choice-3 { background-color: #a6edda; }
  .graph.color-group-7 .choice.choice-4 { background-color: #8ce7cf; }
  .graph.color-group-7 .choice.choice-5 { background-color: #66dfbf; }
  .graph.color-group-7 .choice.choice-6 { background-color: #40d7af; }
  .graph.color-group-7 .choice.choice-7 { background-color: #19cf9e; }

  .graph .choice.na-choice {
    background-image: url('~@/assets/images/stripe.png');
  }
}
.target {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &.bold {
    font-weight: bold;
  }
}
.target-tooltip {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 8px;
  padding: 8px 0;
  font-size: $font_size_12;
  letter-spacing: normal;
  color: #707070;
  white-space: pre-wrap;
  .light {
    font-weight: $font_weight_400;
  }
  span {
    text-align: center;
  }
}
@media only screen and (max-width: $max_width_size_for_mobile) {
  .percent-stacked-bar {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
</style>
<style lang="scss">
.percent-stacked-bar .graph .choice .v-popover {
  width: 100%;
  height: 100%;
  .trigger {
    width: 100%;
    height: 100%;
  }
}
</style>
