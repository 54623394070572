<template>
<div class="summary-table">
  <div class="header">
    <div class="target">{{ $t('trial_question_summary.target') }}</div>
    <div class="average">
      <span @click="sortByAverage">{{ $t('trial_question_summary.average') }}</span>
      <input type="button" class="sort" :class="{
        asc: averageSort === sortType.ASC,
        desc: averageSort === sortType.DESC
      }" @click="sortByAverage"/>
    </div>
    <div class="stn-dvt">
      <span @click="sortByStnDvt">{{ $t('trial_question_summary.deviation') }}</span>
      <input type="button" class="sort" :class="{
        asc: stnDvtSort === sortType.ASC,
        desc: stnDvtSort === sortType.DESC
      }" @click="sortByStnDvt"/>
    </div>
  </div>
  <div class="contents" v-for="(question, index) in sortedQuestions" :key="index"
    @click="moveToQuestion(question)">
    <div class="target" :title="question.text">
      <div class="no">Q{{question.no}}</div>
      <div v-if="question.keyword" class="keyword">{{question.keyword}}</div>
    </div>
    <div class="average" :class="{
      green: maxAverage !== minAverage && question.average === maxAverage,
      red: maxAverage !== minAverage && question.average === minAverage,
    }">{{$formatValue(question.average)}}</div>
    <div class="stn-dvt" :class="{
      green: maxStnDvt !== minStnDvt && question.stnDvt === minStnDvt,
      red: maxStnDvt !== minStnDvt && question.stnDvt === maxStnDvt,
    }">{{$formatValue(question.stnDvt)}}</div>
  </div>
</div>
</template>

<script>
import { sortType } from '@/assets/js/code';

export default {
  name: 'SummaryTable',
  props: {
    questions: {
      type: Array,
    },
  },
  data() {
    return {
      sortType,
      averageSort: sortType.NONE, // 'NONE', 'asc', 'desc'
      stnDvtSort: sortType.NONE, // 'NONE', 'asc', 'desc'
    };
  },
  computed: {
    sortedQuestions() {
      if (this.averageSort === this.sortType.ASC) {
        return this._.orderBy(this.questions,
          q => [q.average, q.no.substr(1)],
          [sortType.ASC, sortType.ASC]);
      }
      if (this.averageSort === this.sortType.DESC) {
        return this._.orderBy(this.questions,
          q => [q.average, q.no.substr(1)],
          [sortType.DESC, sortType.ASC]);
      }
      if (this.stnDvtSort === this.sortType.ASC) {
        return this._.orderBy(this.questions,
          q => [q.stnDvt, q.no.substr(1)],
          [sortType.ASC, sortType.ASC]);
      }
      if (this.stnDvtSort === this.sortType.DESC) {
        return this._.orderBy(this.questions,
          q => [q.stnDvt, q.no.substr(1)],
          [sortType.DESC, sortType.ASC]);
      }
      return this.questions;
    },
    minAverage() {
      return this._.minBy(this.questions, 'average').average;
    },
    maxAverage() {
      return this._.maxBy(this.questions, 'average').average;
    },
    minStnDvt() {
      return this._.minBy(this.questions, 'stnDvt').stnDvt;
    },
    maxStnDvt() {
      return this._.maxBy(this.questions, 'stnDvt').stnDvt;
    },
  },
  methods: {
    moveToQuestion(question) {
      const no = question.no.split('-')[0];
      const element = document.getElementById(`question-result-${no}`);
      if (element) {
        const defaultSize = document.getElementById('question-summary').offsetTop - 60;
        document.getElementById('feedback-scroll').scrollTo({
          top: element.offsetTop + defaultSize,
          behavior: 'smooth',
        });
      }
    },
    sortByAverage() {
      this.stnDvtSort = this.sortType.NONE;
      if (this.averageSort === this.sortType.NONE) {
        this.averageSort = this.sortType.ASC;
      } else if (this.averageSort === this.sortType.ASC) {
        this.averageSort = this.sortType.DESC;
      } else {
        this.averageSort = this.sortType.NONE;
      }
    },
    sortByStnDvt() {
      this.averageSort = this.sortType.NONE;
      if (this.stnDvtSort === this.sortType.NONE) {
        this.stnDvtSort = this.sortType.ASC;
      } else if (this.stnDvtSort === this.sortType.ASC) {
        this.stnDvtSort = this.sortType.DESC;
      } else {
        this.stnDvtSort = this.sortType.NONE;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/style/variable.scss';
.summary-table{
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  .header{
    display: flex;
    flex-direction: row;
    height: 41px;
    align-items: center;
    background-color: #F2F3F4;
    font-size: $font_size_14;
    font-weight: 600;
    color: #2a324b;
    padding-left: 16px;
    padding-right: 16px;
    .average, .stn-dvt {
      display: flex;
      flex-direction: row;
      align-items: center;
      & > span {
        cursor: pointer;
      }
      .sort {
        background-color: transparent;
        border: none;
        background-image: url("~@/assets/images/sort-default.svg");
        background-repeat: no-repeat;
        background-position: 0;
        cursor: pointer;
        margin-left: 4.7px;
        &.asc {
          background-image: url("~@/assets/images/sort-asc.svg");
        }
        &.desc {
          background-image: url("~@/assets/images/sort-desc.svg");
        }
        &:focus {
          outline: none;
        }
      }
    }
  }
  .contents{
    display: flex;
    flex-direction: row;
    height: 41px;
    align-items: center;
    font-size: $font_size_14;
    color: #242424;
    line-height: 1.36;
    border-bottom: 1px solid #dadce0;
    cursor: pointer;
    .target, .average, .stn-dvt{
      padding-left: 16px;
      .keyword {
        white-space: nowrap;
      }
    }
    .average, .stn-dvt {
      &.red {
        font-weight: 600;
        color: rgba(244, 83, 83, 0.93);
      }
      &.green {
        font-weight: 600;
        color: $primary_color;
      }
    }
    &:hover {
      background-color: rgba(0, 202, 148, 0.1);
    }
  }
  .target{
    display: flex;
    flex-direction: row;
    width: 250px;
    white-space: pre-wrap;
    word-wrap: break-word;
    .no {
      width:27px;
    }
    .keyword{
      height: 18px;
      border-radius: 3px;
      background-color: #f2f3f4;
      font-size: $font_size_11;
      font-weight: $font_weight_400;
      color: #2a324b;
      padding: 1px 5px 2px 5px;
      margin-left: 8px;
    }
  }
  .average, .stn-dvt{
    min-width: 100px;
  }
}
@media only screen and (max-width: $max_width_size_for_mobile) {
  .summary-table {
    .header {
      padding: 0 10px;
      .target,
      .average span,
      .stn-dvt span {
        font-size: $font_size_11;
      }
    }
    .contents {
      .target,
      .average,
      .stn-dvt {
        padding: 0 10px;
      }
    }
  }
}
</style>
