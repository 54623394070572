var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"summary-table"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"target"},[_vm._v(_vm._s(_vm.$t('trial_question_summary.target')))]),_c('div',{staticClass:"average"},[_c('span',{on:{"click":_vm.sortByAverage}},[_vm._v(_vm._s(_vm.$t('trial_question_summary.average')))]),_c('input',{staticClass:"sort",class:{
        asc: _vm.averageSort === _vm.sortType.ASC,
        desc: _vm.averageSort === _vm.sortType.DESC
      },attrs:{"type":"button"},on:{"click":_vm.sortByAverage}})]),_c('div',{staticClass:"stn-dvt"},[_c('span',{on:{"click":_vm.sortByStnDvt}},[_vm._v(_vm._s(_vm.$t('trial_question_summary.deviation')))]),_c('input',{staticClass:"sort",class:{
        asc: _vm.stnDvtSort === _vm.sortType.ASC,
        desc: _vm.stnDvtSort === _vm.sortType.DESC
      },attrs:{"type":"button"},on:{"click":_vm.sortByStnDvt}})])]),_vm._l((_vm.sortedQuestions),function(question,index){return _c('div',{key:index,staticClass:"contents",on:{"click":function($event){return _vm.moveToQuestion(question)}}},[_c('div',{staticClass:"target",attrs:{"title":question.text}},[_c('div',{staticClass:"no"},[_vm._v("Q"+_vm._s(question.no))]),(question.keyword)?_c('div',{staticClass:"keyword"},[_vm._v(_vm._s(question.keyword))]):_vm._e()]),_c('div',{staticClass:"average",class:{
      green: _vm.maxAverage !== _vm.minAverage && question.average === _vm.maxAverage,
      red: _vm.maxAverage !== _vm.minAverage && question.average === _vm.minAverage,
    }},[_vm._v(_vm._s(_vm.$formatValue(question.average)))]),_c('div',{staticClass:"stn-dvt",class:{
      green: _vm.maxStnDvt !== _vm.minStnDvt && question.stnDvt === _vm.minStnDvt,
      red: _vm.maxStnDvt !== _vm.minStnDvt && question.stnDvt === _vm.maxStnDvt,
    }},[_vm._v(_vm._s(_vm.$formatValue(question.stnDvt)))])])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }