<template>
  <div class="trial-feedback">
    <div class="scroll">
      <trial-result-basic-info />
      <div class="wrapper">
        <div class="inner">
          <trial-total-average />
          <trial-visualization />
          <trial-question-summary />
        </div>
        <copyright />
      </div>
    </div>
  </div>
</template>

<script>
import TrialResultBasicInfo from '@/components/Result/TrialResultBasicInfo.vue';
import TrialTotalAverage from '@/components/Result/TrialTotalAverage.vue';
import TrialQuestionSummary from '@/components/Result/TrialQuestionSummary.vue';
import TrialVisualization from '@/components/Result/TrialVisualization.vue';
import Copyright from '@/components/Common/Copyright.vue';

export default {
  name: 'TrialFeedback',
  components: {
    TrialQuestionSummary,
    TrialTotalAverage,
    TrialResultBasicInfo,
    TrialVisualization,
    Copyright,
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/style/variable.scss';
.trial-feedback {
  width: 100%;
  min-width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  .scroll {
    width: 100%;
    height: 100%;
    overflow: auto;
    .wrapper {
      padding: $standard_padding_size 0 0 0;
      max-width: $max_width;
      margin: auto;
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      .inner {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
</style>
